<template>
  <div class="bg-white" :class="classes">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Size } from '@/types/enums'

const props = defineProps({
  fullSizeBreakPoint: {
    type: String as PropType<Size> | undefined,
    default: undefined,
  },
})

const classes = computed(() => {
  if (props.fullSizeBreakPoint) {
    return `${props.fullSizeBreakPoint}:shadow-md ${props.fullSizeBreakPoint}:rounded-2xl`
  }

  return 'shadow-md rounded-2xl'
})
</script>
